import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import {
  OrdersHealthCheckState,
  OrdersHealthCheckStore,
} from './orders-health-check-store.types';
import { devtools } from 'zustand/middleware';

export const initialState: OrdersHealthCheckState = {
  prevalidation: { results: [], isLoading: true, isError: false },
};

export const useOrdersHealthCheckStoreBase = create<OrdersHealthCheckStore>()(
  devtools(
    immer(set => ({
      ...initialState,
      resetStore: () => set(initialState, false, 'resetStore'),
      setPrevalidationIsLoading: isLoading =>
        set(
          state => {
            state.prevalidation.isLoading = isLoading;
          },
          false,
          { type: 'setPrevalidationIsLoading', isLoading },
        ),
      setPrevalidationIsError: isError =>
        set(
          state => {
            state.prevalidation.isError = isError;
          },
          false,
          { type: 'setPrevalidationIsError', isError },
        ),
      setPrevalidationResults: results =>
        set(
          state => {
            state.prevalidation.results = results;
            state.prevalidation.isLoading = false;
            state.prevalidation.isError = false;
          },
          false,
          { type: 'setPrevalidationResults', results },
        ),
      addPrevalidationResults: results =>
        set(
          state => {
            const existingOrderIds = new Set(
              state.prevalidation.results.map(order => order.orderId),
            );
            results.forEach(result => {
              if (!existingOrderIds.has(result.orderId)) {
                state.prevalidation.results.push(result);
              } else {
                const existingOrder = state.prevalidation.results.find(
                  order => order.orderId === result.orderId,
                );
                if (existingOrder) {
                  // Update the existing order with new errors/warnings only if it was not updated after prevalidation
                  // This is to prevent overwriting the errors/warnings with the same data stored in react query cache
                  // This is important for the case when the order was edited after prevalidation, since it could happens that
                  // the order does not have any errors/warnings after the edit but the cached data still has the errors/warnings
                  // leading to wrongly showing the errors/warnings badge in UI
                  if (!existingOrder.isUpdatedAfterPrevalidation) {
                    existingOrder.errors = result.errors;
                    existingOrder.warnings = result.warnings;
                    existingOrder.isUpdatedAfterPrevalidation =
                      result.isUpdatedAfterPrevalidation;
                  }
                } else {
                  state.prevalidation.results.push(result);
                }
              }
            });
          },
          false,
          { type: 'addPrevalidationResults', results },
        ),
      // This method is used to update the order prevalidation results
      // after the order was edited and the prevalidation was triggered again.
      // This function bypass the check on isUpdatedAfterPrevalidation boolean
      updateOrderPrevalidation: order =>
        set(
          state => {
            const existingOrder = state.prevalidation.results.find(
              o => o.orderId === order.orderId,
            );
            if (existingOrder) {
              existingOrder.errors = order.errors;
              existingOrder.warnings = order.warnings;
              existingOrder.isUpdatedAfterPrevalidation =
                order.isUpdatedAfterPrevalidation;
            }
          },
          false,
          { type: 'updateOrderPrevalidation', order },
        ),
    })),
    {
      name: 'OrdersHealthCheckStore',
      enabled: process.env.NODE_ENV !== 'production',
    },
  ),
);

export const useOrdersHealthCheckStore = createSelectorHooks(
  useOrdersHealthCheckStoreBase,
) as typeof useOrdersHealthCheckStoreBase &
  ZustandHookSelectors<OrdersHealthCheckStore>;

export * from './orders-health-check-store.types';
