export type PrevalidatedOrder = {
  orderId: number;
  isUpdatedAfterPrevalidation: boolean;
  errors: PrevalidationResult[];
  warnings: PrevalidationResult[];
};

export type PrevalidationResult = Record<string, PrevalidatedField[]>;

export type PrevalidatedField = {
  [key: string]: string[];
};

export type PrevalidationState = {
  results: PrevalidatedOrder[];
  isLoading: boolean;
  isError: boolean;
};

export type OrdersHealthCheckState = {
  prevalidation: PrevalidationState;
};

export type OrdersHealthCheckActions = {
  resetStore: () => void;
  setPrevalidationIsLoading: (isLoading: boolean) => void;
  setPrevalidationIsError: (isError: boolean) => void;
  setPrevalidationResults: (prevalidationResults: PrevalidatedOrder[]) => void;
  addPrevalidationResults: (orders: PrevalidatedOrder[]) => void;
  updateOrderPrevalidation: (order: PrevalidatedOrder) => void;
};

export type OrdersHealthCheckStore = OrdersHealthCheckState &
  OrdersHealthCheckActions;
